import './bootstrap';
import flatpickr from 'flatpickr';

const $dateTimePickers = document.querySelectorAll( '.js-date-time' );
const $datePickers = document.querySelectorAll( '.js-date' );
const $timePickers = document.querySelectorAll( '.js-time' );

console.log(
    {
        $dateTimePickers,
        $datePickers,
        $timePickers
    }
)

if ( $dateTimePickers ) {
    $dateTimePickers.forEach( $picker => {
        flatpickr( $picker, {
            altInput: true,
            enableTime: true,
            altFormat: 'F j, Y H:i',
            dateFormat: 'd-m-Y H:i',
            minDate: 'today'
        } )
    } )
}

if ( $datePickers ) {
    $datePickers.forEach( $picker => {
        flatpickr( $picker, {
            altInput: true,
            altFormat: 'F j, Y',
            dateFormat: 'd-m-Y',
            minDate: 'today'
        } )
    } )
}

if ( $timePickers ) {
    $timePickers.forEach( $picker => {
        flatpickr( $picker, {
            altInput: true,
            altFormat: 'H:i',
            dateFormat: 'H:i',
        } )
    } )
}
